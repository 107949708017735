.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.body {
  background-color: #282c34;
}

.App-link {
  color: #61dafb;
}

.container {
  display: grid;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
react-datepicker__time-list-item--disabled{
  display:none
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled{
  display:none
}

.datepicker-time-out-of-range {
   color: grey;
   pointer-events: none;
}

.react-datepicker__input-container {
  box-sizing: border-box;
  color: var(--amplify-components-fieldcontrol-color);
  font-size: var(--amplify-components-fieldcontrol-font-size);
  line-height: var(--amplify-components-fieldcontrol-line-height);
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding: 0;
  transition: all var(--amplify-components-fieldcontrol-transition-duration);
  width: 100%;
  border-color: var(--amplify-components-fieldcontrol-border-color);
  border-radius: var(--amplify-components-fieldcontrol-border-radius);
  border-style: var(--amplify-components-fieldcontrol-border-style);
  border-width: var(--amplify-components-fieldcontrol-border-width);
  outline-color: var(--amplify-components-fieldcontrol-outline-color);
  outline-style: var(--amplify-components-fieldcontrol-outline-style);
  outline-width: var(--amplify-components-fieldcontrol-outline-width);
  outline-offset: var(--amplify-components-fieldcontrol-outline-offset);
  background-color: var(--amplify-components-select-background-color);
  color: var(--amplify-components-select-color);
  min-width: var(--amplify-components-select-min-width);
  white-space: var(--amplify-components-select-white-space);
}

.datetime-label{
  color: var(--amplify-components-field-label-color);
  font-size: var(--amplify-components-field-font-size);
  margin-bottom: -2.0em; 
  flex-direction: var(--amplify-components-selectfield-flex-direction);
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

.react-datepicker__triangle {
  display: none !important;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure the container takes full width */
}

.floating-image {
  flex: 0 0 60%; /* Flex-grow, flex-shrink, flex-basis */
  max-width: 60%; /* Maximum width */
  border-radius: 20px;
  animation: float 3s ease-in-out infinite;
}

.text-content {
  flex: 1; /* Allows the text content to fill the rest of the space */
  padding-left: 20px; /* Optional spacing between the image and text */
  padding-right: 100px;
}

.image-container {
  flex: 0 0 60%; /* Adjust the size to 40% of the content area */
  text-align: center; /* Center the title and image */
  position: relative; /* Needed for absolute positioning of the title */
  margin-right: -5%; 
  margin-left: -10%; 
}
@keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px); /* Adjust the float height as needed */
  }
}

.fun-image {
  flex: 0 0 60%; /* Flex-grow, flex-shrink, flex-basis */
  max-width: 25%; /* Maximum width */
  border-radius: 20px;
}
.fun-image-container {
  flex: 0 0 30%; /* Adjust the size to 40% of the content area */
  text-align: center; /* Center the title and image */
  position: relative; /* Needed for absolute positioning of the title */
  margin-right: auto; 
  margin-left: -10%; 
}
.image-title {
  font-size: 32px;
  color: #3fdfaf;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.image-title:hover {
  color: rgb(215, 255, 250);
}
.quick-stats {
  display:flex;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 0;
  gap: 0px; /* Space between items */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  align-items: left; /* Align items vertically in the center */
  justify-content: center;
  flex-direction: column;
}

.quick-stats p {
  margin: 0;
  padding: 0;
}
.movie-details {
  flex: 0 0 0%;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}
.brads-guess {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}
.fun-button {
  background-color: #0000000e; /* Vibrant color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center items horizontally */
  gap: 10px; /* Space between items */
  flex-direction: row; 
}
.fun-button button{
  background-color: #df3f54; /* Vibrant color */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center items horizontally */
  gap: 10px; /* Space between items */
  flex-direction: row; 
}
.fun-button p {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-size: 16px; /* Match font size with buttons */
}
.fun-button button:hover {
  background-color: #630123; /* Slightly darker on hover */
  color: rgb(215, 255, 250);
  font-size: 18px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}
.brads-guess {
  color: #3fdfaf;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}